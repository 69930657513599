import {ChangeDetectionStrategy, Component, EventEmitter, input, Input, Output} from '@angular/core'
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons/faArrowRight'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'

@Component({
  selector: 'cft-content-section',
  templateUrl: './content-section.component.html',
  styles: [':host { @apply block; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FaIconComponent],
  standalone: true,
})
export class ContentSectionComponent {
  sectionId = input<string>()
  @Input() plot?: string | null
  @Input() title?: string
  @Input() titleAlignment: 'left' | 'center' = 'center'
  @Input() titleButtonId?: string
  @Input() titleButton?: string
  @Input() description?: string
  @Input() actionHeader?: string
  @Input() actionAlignment?: 'left' | 'center' = 'center'
  @Input() mainCallToAction?: string
  @Input() secondaryCallToAction?: string
  @Output() mainCallToActionClick = new EventEmitter<void>()
  @Output() secondaryCallToActionClick = new EventEmitter<void>()
  @Output() titleButtonClick = new EventEmitter<void>()

  readonly titleButtonIcon = faArrowRight
}
